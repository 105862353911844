<template>
  <section class="dtc-main-section6 doc6 mt-4" id="">
    <div
      class="dtc-print"
      v-for="(topItem, topIndex) in dtcGridSize"
      :key="topIndex"
      :style="`--minHeightOne:${minheight1}px;--minHeightTwo:${minheight2}px`"
      style="page-break-after:always;pointer-events:none;"
    >
      <section class="dtc-print8-grid-header">
        <span
          class="dtc-title"
          v-if="topIndex == 0"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ schoolName }}學校{{ bYear }}學年度第{{
            term
          }}學期「原住民族及離島地區醫事人員養成計畫」
          教學設備費補助經費表(核銷用)
        </span>

        <!-- div class="dtc-fill-required2" style="padding-top:25px">學系</div-->
        <!-- div class="dtc-fill-required" style="padding-top:25px">年級</div-->
        <!-- div class="dtc-fill-required" style="padding-top:25px">人數</div-->
        <!-- div class="dtc-fill-required" style="padding-top:25px">每名補助費</div-->
        <!-- div class="dtc-line2" style="padding-top:25px">小計</div-->
        <div class="dtx-col3">
          <!-- <div style="grid-column:1/-1; grid-row: 1">採購設備項目</div> -->
          <div class="dtc-fill-required">設備名稱</div>
          <div class="dtc-fill-required">採購金額</div>
          <div class="dtc-fill-required dtc-grid-2">
            <div style="border-top:0px;border-right:0px;opacity:0">.</div>
            <div style="border-top:0px;border-left:0px;border-right:0px;"></div>
            <div style="font-size:12px !important;">衛生福利部補助款</div>
            <div style="font-size:12px !important;border-right:0px;">
              學校自籌款
            </div>
          </div>
        </div>
        <div class="dtc-fill-required" style="padding-top:25px">備註</div>
        <div class="dtc-fill-required" style="padding-top:25px">用途說明</div>
      </section>

      <main class="dtc-print8-grid-main">
        
        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: center; height: 100px;"
          >
            {{ item.DeviceName }}
          </div>
        </div>

        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: right;padding-right: 5px; height: 100px;"
          >
            {{ $formatPrice(item.Apply_Fee) }}
          </div>
        </div>
        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: right;padding-right: 5px; height: 100px;"
          >
            {{ $formatPrice(item.Approved_Fee) }}
          </div>
        </div>
        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: right;padding-right: 5px; height: 100px;"
          >
            {{ $formatPrice(item.School_Fee) }}
          </div>
        </div>
        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: center; height: 100px;  font-size: 12px;"
          >
            {{ item.Remark || "　" }}
          </div>
        </div>
        <div class="row-4-dtc-equipment">
          <div
            v-for="(item, i) in map.equipments"
            :key="i"
            style="text-align: center; height: 100px;"
          >
            <span :style="[{ color: !Boolean(item.Purpose) ? '#fff' : '', fontSize: '12px' }]">{{
              item.Purpose || "."
            }}</span>
          </div>
        </div>
      </main>

      <footer class="dtc-f1" v-if="topItem === dtcGridSize" style="border-top: 1px solid var(--dtc-border-color);">
        <div>總計</div>
        <div style="text-align: right;padding-right: 5px;">
          {{ $formatPrice(total1) }}
        </div>
        <div style="text-align: right;padding-right: 5px;">
          {{ $formatPrice(total2) }}
        </div>
        <div style="text-align: right;padding-right: 5px;">
          {{ $formatPrice(total3) }}
        </div>
        <div>賸餘款</div>
        <div>
          {{
            +map.DeviceTotal - +total1 > 0
              ? $formatPrice(map.DeviceTotal - total1)
              : 0
          }}
        </div>
      </footer>
      <!--<footer class="dtc-f2" v-if="topItem === dtcGridSize">
        <div>賸餘款</div>
        <div>
          {{
            +map.DeviceTotal - +total1 > 0
              ? $formatPrice(map.DeviceTotal - total1)
              : 0
          }}
        </div>
      </footer>-->
      
      <section class="dtc-print8-grid0625-header" style="margin-top: 30px;">
        <span
          class="dtc-title"
          v-if="topIndex == 0"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
        </span>
        <div class="dtc-fill-required2" style="padding-top:25px">學系</div>
        <div class="dtc-fill-required" style="padding-top:25px">年級</div>
        <div class="dtc-fill-required" style="padding-top:25px">每名補助費</div>
        <div class="dtc-fill-required" style="padding-top:25px">人數</div>
        <div class="dtc-line2" style="padding-top:25px">小計</div>
      </section>
      <main class="dtc-print8-grid0625-main" v-if="topItem === dtcGridSize">
        <div class="row-4-dtc">
          <div
            v-for="(item, i) in map.budgeting_all"
            :key="i"
            :style="
              item.MajorName && item.MajorName.length > 6
                ? 'min-height:50px'
                : ''
            "
          >
            {{ item.MajorName }}
          </div>
        </div>
        <div class="row-4-dtc">
          <div
            v-for="(item, i) in map.budgeting"
            :key="i"
            :style="
              item.MajorName && item.MajorName.length > 6
                ? 'min-height:50px'
                : ''
            "
          >
            {{ item.GradeName }}
          </div>
        </div>
        <div class="row-4-dtc">
          <div
            v-for="(item, i) in map.budgeting"
            :key="i"
            style="text-align: right;padding-right: 5px;"
            :style="
              item.MajorName && item.MajorName.length > 6
                ? 'min-height:50px'
                : ''
            "
          >
            {{ $formatPrice(item.Fee_12 / item.NumberOfPeople) }}
          </div>
        </div>
        <div class="row-4-dtc">
          <div
            v-for="(item, i) in map.budgeting"
            :key="i"
            :style="
              item.MajorName && item.MajorName.length > 6
                ? 'min-height:50px'
                : ''
            "
          >
            {{ item.NumberOfPeople }}
          </div>
        </div>
        <div class="row-4-dtc">
          <div
            v-for="(item, i) in map.budgeting"
            :key="i"
            style="text-align: right;padding-right: 5px;"
            :style="
              item.MajorName && item.MajorName.length > 6
                ? 'min-height:50px'
                : ''
            "
          >
            {{ $formatPrice(item.Fee_12) }}
          </div>
        </div>       
      </main>
      <footer class="dtc-f6" v-if="topItem === dtcGridSize">
        <div>總計</div>
        <div  style="min-height:50px">
          {{ $formatPrice(total4) }}
        </div>
        <div style="text-align: right;padding-right: 5px;">
          {{ $formatPrice(total5) }}
        </div>
      </footer>


      <div class="dtc-print">
        <footer class="dtc-f3 dtx-col4" v-if="topItem === dtcGridSize">
          <div>承辦單位</div>
          <div>註冊組(或學籍稽核單位)</div>
          <div>出納組</div>
          <div>會計(財務)</div>
          <div>校長(或授權人)</div>
        </footer>
      </div>
      <!--<footer class="dtc-f4" v-if="topItem === dtcGridSize">
        <div>【備註】</div>
        <div>
          1.
          醫學系及牙醫學系公費生每名每年新台幣3萬元，其他學系公費生每名每年新台幣1萬5,000元。
        </div>
        <div>
          2.本案補助設備定義為金額一萬元以上且使用年限，在兩年以上，依規定應編製財產增加單。
        </div>
        <div>
          3.設備採購金額或品項倘有變更，應於
          <span style="color:red;"
            >每年11月底前函報本部進行變更，並以一次為原則。</span
          >
        </div>
        <div>
          4.申請金額係為本學期因執行本計畫所申請之教學設備補助經費，非使用本計畫經費者，無須採計。
        </div>
        <div>
          5.倘有申請金額與總補助額度有差異或是有不同經費來源(如經費分攤等)，致經費有所差異，請於備註欄位處說明，以利核對。
        </div>
      </footer>-->
      <b-button
        hidden
        v-if="topItem === dtcGridSize"
        @click="++dtcGridSize"
        class="d-print-none dtc-add dtc-print-btn2"
        variant="primary"
        size="sm"
        style="border-right:none;"
        >增加總表</b-button
      >
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array(200).fill().map((s, t) => {
  return {
    myKey: t,
    NumberOfPeople: "",
    ApplyOfPeople: "",
    PeopleFee: "",
    SubTotal: "",
    DeviceName: "",
    Grade: "",
    Apply_Fee: "",
    Approved_Fee: "",
    Remark: "",
    Purpose: "",
    schoolName: "",
  };
});

export default {
  name: "totalSpend",
  data() {
    return {
      twYears,
      majors: [],
      level6,
      map: "",
      category: [],
      schoolArr: [],
      total1Footer: "",
      dtcGridSize: 1,
      pageRows,
      learnSpendFooter: 0,
      insureFooter: 0,
      othersFooter: 0,
      eatFooter: 0,
      allowFooter: 0,
      classFooter: 0,
      bookFooter: 0,
      clothFooter: 0,
      travelFooter: 0,
      backHomeFooter: 0,
      liveFooter: 0,
      sumFooter: 0,
      pageSize: 13,
      //pageSize: 13,
      totalList: [],
      applyTotalFooter: "",
      approveTotalFooter: "",
      schoolFeeTotalFooter: "",
      term: 1,
      bYear: "108",
      debug: window.dtcDebug,
      schoolName: "",
      minheight1: 20,
      minheight2: 20,

      total1: 0,
      total2: 0,
      total3: 0,
      total4: 0,
      total5: 0,
    };
  },

  methods: {
    async getData() {
      //const allSchools = JSON.parse(localStorage.dtcSchoolList);
      const {
        schoolcode: SchoolCode,
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.bYear = Byear;
      this.term = +term;
      const id = SchoolCode ? SchoolCode : "";
      const url = `Equipment/GetEquipmentHead?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`;
      let map = "";
      try {
        map = await window.axios.get(url);

        const equipment = [...map.equipments];
        console.log(map);
        this.total1 = 0;
        this.total2 = 0;
        this.total3 = 0;
        this.total4 = 0;
        this.total5 = 0;
        equipment.forEach((s) => {
          this.total1 += s.Apply_Fee;
          this.total2 += s.Approved_Fee;
          this.total3 += s.School_Fee;
        });
        const budgeting = [...map.budgeting];
        budgeting.forEach((s) => {
          this.total4 += s.NumberOfPeople;
          this.total5 += s.Fee_12;
        });
        this.map = map;

        if (map.budgeting_all.length > map.equipments.length) {
          this.minheight1 = 20;
          this.minheight2 =
            (map.budgeting_all.length * 20) / map.equipments.length;
        } else {
          this.minheight1 =
            (map.equipments.length * 20) / map.budgeting_all.length;
          this.minheight2 = 20;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {},
  async beforeMount() {
    // this.$root.$on("dtc-save-form", () => this.save());
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = 1,
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    const id = SchoolCode ? SchoolCode : "0003";
    this.schoolArr = await window.axios.get("School/GetAll");
    this.schoolName = this.schoolArr.find((s) => s.SchoolCode == id).SchoolName;
    let arr = [];
    let url = `Major/Get?schoolcode=${SchoolCode}`;
    try {
      arr = await window.axios.get(`Major/Get?schoolcode=${SchoolCode}`);
    } catch (e) {
      const str = e + " API: " + url;
      this.$root.$emit("dtc-server-error", str);
    }
    let majors = [];
    let arr2 = [];
    arr.forEach((s) => {
      majors.push(`${s.MajorName}--${s.MajorId}`);
      arr2.push({ value: s.MajorId, text: `${s.MajorName}` });
    });
    this.category = arr2;
    this.majors = majors;

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != SchoolCode) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
};
</script>

<style lang="scss">
.doc6 .dropdown-toggle {
  max-width: 48px !important;
  min-width: 48px !important;
  width: 48px;
  font-size: 14px;
  min-height: 37px;
  //border-radius: none !important;
  color: black !important;
  background: transparent !important;
  &::after {
    all: unset;
  }
}
</style>

<style lang="scss">
main .custom-select {
  background: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-remove {
  position: absolute;
  top: 0px;
  left: -20px;
  color: red;

  font-size: 13px;
  text-align: center;

  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid red;
}
.dtc-print {
  position: relative;
  --dtc-border-color: var(--dark);
  display: grid;
  margin-top: 30px;
  margin: 30px auto 2px auto;
  width: 1400px;
  margin-bottom: 60px;
  .input1 {
    border: none;
    max-width: 49px;
    padding: 3px;
  }
  .input2 {
    border: none;
    max-width: 89px !important;
    // height: 30px;
    display: inline-block;
    padding: 3px;
  }
}

textarea,
input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 2px;
}

.dtc-print8-grid0625-header,
.dtc-f6,
.dtc-print8-grid0625-main {
  position: relative;
  z-index: 13px;
  font-size: 16px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: repeat(5, 272px);
  > div {
    min-height: 70px;
    border-right: 1px solid var(--dtc-border-color);
    // line-height: 70px;
    text-align: center;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-print8-grid0625-main,
.dtc-f6,
.dtc-f5 {
  grid-template-columns: repeat(5, 272px);
  border-top: none;
  > div {
    //min-height: 38px;
    line-height: 50px;
    font-size: 16px;
  }
}
.dtc-f6 {
  grid-template-columns: 816px repeat(2, 272px);
  > div {
    height: 90px;
    width: 100%;
    // line-height: 22px;
  }
}

.dtc-line2 {
  position: relative;
  &::after {
    position: absolute;
    display: block;
    top: 60%;
    left: 3px;
    width: 100%;
    content: "(人數*每名補助費)";
    font-size: 15px;
    transform: scale(0.75);
  }
}

.dtx-col3 {
  display: grid;
  grid-template-columns: repeat(2, 180px) 360px;
  align-items: end;
  > div {
    height: 60px;
    border-top: 1px solid var(--dtc-border-color);
    border-right: 1px solid var(--dtc-border-color);
    // line-height: 45px;
  }
  > div:last-child {
    border-right: none;
  }
  &::after {
    position: absolute;
    display: block;
    top: 2px;
    left: 35%;
    transform: translateX(-250%);
    content: "採購設備項目";
  }
}
.dtx-col4 {
  display: grid;
  grid-template-columns: repeat(2, 200px) 400px;
  align-items: end;
  > div {
    height: 45px;
    border-top: 1px solid var(--dtc-border-color);
    border-right: 1px solid var(--dtc-border-color);
    // line-height: 45px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-2 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 180px);
  > div {
    // max-height: 22px;
    border-top: 1px solid var(--dtc-border-color);
    border-right: 1px solid var(--dtc-border-color);
    // line-height: 22px;
  }
  &::before {
    position: absolute;
    font-size: 13px;
    display: block;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
    content: "核銷金額";
  }
}

.dtc-page {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateY(65px);
  border: none;
}
.dtc-add {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateX(80px);
  border: none;
}
.dtc-title {
  position: absolute;
  top: -5px;
  left: 50%;
  display: inline-block;
  text-align: center;
  width: 1250px;
  transform: translate(-50%, -30px);
  font-size: 16px;
  font-weight: bold;
}

.dtc-print8-grid-header,
.dtc-f1,
.dtc-f2,
.dtc-f3,
.dtc-print8-grid-main {
  position: relative;
  z-index: 13px;
  font-size: 16px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: 720px 240px 400px;
  > div {
    min-height: 90px;
    border-right: 1px solid var(--dtc-border-color);
    // line-height: 70px;
    text-align: center;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-print8-grid-main,
.dtc-f2,
.dtc-f3,
.dtc-f5,
.dtc-f1 {
  grid-template-columns: repeat(4, 180px) 240px 400px;
  border-top: none;
  > div {
    // min-height: 38px;
    line-height: 30px;
    //height: 100px; /* 保持 div 的高度与 line-height 一致 */
    font-size: 20px;
  }
}
.dtc-f1 {
  grid-template-columns: 180px 180px repeat(2, 180px) 240px 400px;
}
.dtc-f2 {
  grid-template-columns: 610px 510px;
  //675px 575px
}
.dtc-f3 {
  grid-template-columns: repeat(5, 272px);
  > div {
    height: 90px;
    width: 100%;
    // line-height: 22px;
  }
}
.dtc-f4 {
  grid-column: 1 /-1;
  border: 0px solid var(--dtc-border-color);
  border-top: none;
  padding: 4px;
  font-size: 16px;
}

.row-4-dtc {
  display: flex;
  flex-direction: column;
  > div {
    min-height: var(--minHeightOne);
    border-bottom: 1px solid black;
    font-size: 16px;
  }
  > div:last-child {
    border-bottom: none;
  }
}
.row-4-dtc-equipment {
  display: flex;
  flex-direction: column;

  > div {
    min-height: var(--minHeightTwo);
    // line-height: var(--minHeightTwo);
    border-bottom: 1px solid black;
    font-size: 15px;
  }
  > div:last-child {
    border-bottom: none;
  }
}

.dtc-fill-requiredxxx,
.dtc-fill-required2xx {
  position: relative;
  &::after {
    position: absolute;
    font-size: 16px;
    color: red;
    content: "*";
    top: -5px;
    left: 35px;
  }
}
.dtc-fill-required2::after {
  font-size: 16px;
  left: 80px;
  font-size: 24px;
}
.dtx-tooltip2 {
  select {
    padding-left: 17px;
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media screen {
  .dtc-main-section6 {
    padding-top: 12px;
    position: relative;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-print {
    --dtc-border-color: var(--dark);
  }
}
</style>
